.fullWidth {
    /* TODO proper fix (original was in style attr) */
    width: 100% !important; 
    max-width: none !important;
}

.d-none {
    display: none !important;
}

td, td {
    padding: 0.5em;
}